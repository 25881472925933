import React from 'react';

import { DarkGrey, MainColour, TitleColour } from '../../utils/variables';

function getIconStyle({ size, width, height, colour, style }) {
  /* Set default icon size */
  const defaultSize = '24px';
  /* Set icon width */
  const iconWidth = () => {
    if (size) {
      return size;
    }
    if (width) {
      return width;
    }
    return defaultSize;
  };
  /* Set icon height */
  const iconHeight = () => {
    if (size) {
      return size;
    }
    if (height) {
      return height;
    }
    return defaultSize;
  };
  /* Set icon outer style */
  const iconStyle = {
    display: `inline-block`,
    lineHeight: 0,
    width: iconWidth(),
    height: iconHeight(),
    flex: `0 0 ${iconWidth()}`,
    ...style,
  };

  return { width: iconWidth(), height: iconHeight(), style: iconStyle, colour };
}

/* Calculator icon */
const Calculator = props => {
  const { width, height, style, colour } = getIconStyle(props);
  return (
    <div className="icon" style={style}>
      <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
        <path
          d="M16 5.75C16.4142 5.75 16.75 5.41421 16.75 5V3.5C16.75 3.08579 16.4142 2.75 16 2.75C15.5858 2.75 15.25 3.08579 15.25 3.5V5C15.25 5.41421 15.5858 5.75 16 5.75Z"
          fill={colour || '#fff'}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18 0C19.6569 0 21 1.34315 21 3V21C21 22.6569 19.6569 24 18 24H6C4.34315 24 3 22.6569 3 21V3C3 1.34315 4.34315 0 6 0H18ZM13 15C13 14.4477 12.5523 14 12 14C11.4477 14 11 14.4477 11 15C11 15.5523 11.4477 16 12 16C12.5523 16 13 15.5523 13 15ZM11 10.5C11 9.94771 11.4477 9.5 12 9.5C12.5523 9.5 13 9.94771 13 10.5C13 11.0523 12.5523 11.5 12 11.5C11.4477 11.5 11 11.0523 11 10.5ZM17.25 19.5C17.25 19.0858 16.9142 18.75 16.5 18.75H11C10.5858 18.75 10.25 19.0858 10.25 19.5C10.25 19.9142 10.5858 20.25 11 20.25H16.5C16.9142 20.25 17.25 19.9142 17.25 19.5ZM16.5 16C15.9477 16 15.5 15.5523 15.5 15C15.5 14.4477 15.9477 14 16.5 14C17.0523 14 17.5 14.4477 17.5 15C17.5 15.5523 17.0523 16 16.5 16ZM15.5 10.5C15.5 11.0523 15.9477 11.5 16.5 11.5C17.0523 11.5 17.5 11.0523 17.5 10.5C17.5 9.94771 17.0523 9.5 16.5 9.5C15.9477 9.5 15.5 9.94771 15.5 10.5ZM7.5 16C6.94772 16 6.5 15.5523 6.5 15C6.5 14.4477 6.94772 14 7.5 14C8.05228 14 8.5 14.4477 8.5 15C8.5 15.5523 8.05228 16 7.5 16ZM7.5 20.5C8.05228 20.5 8.5 20.0523 8.5 19.5C8.5 18.9477 8.05228 18.5 7.5 18.5C6.94772 18.5 6.5 18.9477 6.5 19.5C6.5 20.0523 6.94772 20.5 7.5 20.5ZM7.5 11.5C6.94772 11.5 6.5 11.0523 6.5 10.5C6.5 9.94771 6.94772 9.5 7.5 9.5C8.05228 9.5 8.5 9.94771 8.5 10.5C8.5 11.0523 8.05228 11.5 7.5 11.5ZM18 2H6C5.44772 2 5 2.44772 5 3V6.25C5 6.38807 5.11193 6.5 5.25 6.5H18.75C18.8881 6.5 19 6.38807 19 6.25V3C19 2.44772 18.5523 2 18 2Z"
          fill={colour || '#fff'}
        />
      </svg>
    </div>
  );
};

/* Plus icon */
const Plus = props => {
  const { width, height, style, colour } = getIconStyle(props);
  return (
    <div className="icon" style={style}>
      <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.5 12C23.5 18.3513 18.3513 23.5 12 23.5C5.6517 23.4928 0.507164 18.3483 0.5 12C0.5 5.64873 5.64873 0.5 12 0.5C18.3513 0.5 23.5 5.64873 23.5 12ZM10.75 11H6C5.44772 11 5 11.4477 5 12C5 12.5523 5.44772 13 6 13H10.75C10.8881 13 11 13.1119 11 13.25V18C11 18.5523 11.4477 19 12 19C12.5523 19 13 18.5523 13 18V13.25C13 13.1119 13.1119 13 13.25 13H18C18.5523 13 19 12.5523 19 12C19 11.4477 18.5523 11 18 11H13.25C13.1119 11 13 10.8881 13 10.75V6C13 5.44772 12.5523 5 12 5C11.4477 5 11 5.44772 11 6V10.75C11 10.8881 10.8881 11 10.75 11Z"
          fill={colour || TitleColour}
        />
      </svg>
    </div>
  );
};

/* Minus icon */
const Minus = props => {
  const { width, height, style, colour } = getIconStyle(props);
  return (
    <div className="icon" style={style}>
      <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.5 12C23.5 18.3513 18.3513 23.5 12 23.5C5.6517 23.4928 0.507164 18.3483 0.5 12C0.5 5.64873 5.64873 0.5 12 0.5C18.3513 0.5 23.5 5.64873 23.5 12ZM19 12C19 11.4477 18.5523 11 18 11H6C5.44772 11 5 11.4477 5 12C5 12.5523 5.44772 13 6 13H18C18.5523 13 19 12.5523 19 12Z"
          fill={colour || TitleColour}
        />
      </svg>
    </div>
  );
};

/* Edit icon */
const Edit = props => {
  const { width, height, style, colour } = getIconStyle(props);
  return (
    <div className="icon" style={style}>
      <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
        <path
          d="M15.067 3.98598C14.9734 3.89153 14.846 3.83828 14.713 3.83798C14.5802 3.83801 14.4528 3.8909 14.359 3.98498L3.437 14.91C3.24181 15.1052 3.24181 15.4217 3.437 15.617L8.385 20.565C8.58025 20.7602 8.89675 20.7602 9.092 20.565L20.009 9.64798C20.2035 9.45285 20.2035 9.13712 20.009 8.94198L15.067 3.98598Z"
          fill={colour || TitleColour}
        />
        <path
          d="M2.43 16.8C2.30195 16.6724 2.11497 16.6238 1.941 16.673C1.76674 16.7219 1.6325 16.8611 1.59 17.037L0.0839979 23.314C0.0435833 23.4831 0.0939495 23.6611 0.216998 23.784C0.340774 23.9055 0.518066 23.9553 0.686998 23.916L6.959 22.416C7.13511 22.3738 7.27466 22.2398 7.32384 22.0655C7.37301 21.8912 7.32411 21.704 7.196 21.576L2.43 16.8Z"
          fill={colour || TitleColour}
        />
        <path
          d="M23.2 2.92399L21.077 0.799987C20.1011 -0.17397 18.5209 -0.17397 17.545 0.799987L16.127 2.21699C15.9318 2.41224 15.9318 2.72874 16.127 2.92399L21.077 7.87299C21.2722 8.06818 21.5887 8.06818 21.784 7.87299L23.2 6.45399C24.1726 5.47831 24.1726 3.89966 23.2 2.92399Z"
          fill={colour || TitleColour}
        />
      </svg>
    </div>
  );
};

/* Check icon */
const Check = props => {
  const { width, height, style, colour } = getIconStyle(props);
  return (
    <div className="icon" style={style}>
      <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
        <path
          d="M23.146 5.39999L20.354 2.59999C20.2602 2.50595 20.1328 2.45309 20 2.45309C19.8672 2.45309 19.7398 2.50595 19.646 2.59999L7.854 14.4C7.7602 14.494 7.63283 14.5469 7.5 14.5469C7.36717 14.5469 7.2398 14.494 7.146 14.4L4.354 11.6C4.2602 11.5059 4.13283 11.4531 4 11.4531C3.86717 11.4531 3.7398 11.5059 3.646 11.6L0.854 14.4C0.65881 14.5952 0.65881 14.9117 0.854 15.107L7.146 21.4C7.2398 21.494 7.36717 21.5469 7.5 21.5469C7.63283 21.5469 7.7602 21.494 7.854 21.4L23.146 6.09999C23.3366 5.90557 23.3366 5.59441 23.146 5.39999Z"
          fill={colour || MainColour}
        />
      </svg>
    </div>
  );
};

/* Address icon */
const Address = props => {
  const { width, height, style, colour } = getIconStyle(props);
  return (
    <div className="icon" style={style}>
      <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
        <path
          d="M23.707 11.293L20.573 8.159C20.526 8.11209 20.4998 8.04837 20.5 7.982V2C20.5 1.44772 20.0523 1 19.5 1H16.5C15.9477 1 15.5 1.44772 15.5 2V2.482C15.5002 2.58325 15.4393 2.67462 15.3457 2.71339C15.2522 2.75217 15.1445 2.73069 15.073 2.659L12.707 0.293C12.3165 -0.0973819 11.6835 -0.0973819 11.293 0.293L0.292999 11.293C0.00709087 11.579 -0.0784231 12.009 0.0763208 12.3827C0.231065 12.7563 0.595603 12.9999 0.999999 13H2.25C2.38807 13 2.5 13.1119 2.5 13.25V23C2.5 23.5523 2.94771 24 3.5 24H9.25C9.38807 24 9.5 23.8881 9.5 23.75V19C9.5 17.6193 10.6193 16.5 12 16.5C13.3807 16.5 14.5 17.6193 14.5 19V23.75C14.5 23.8881 14.6119 24 14.75 24H20.5C21.0523 24 21.5 23.5523 21.5 23V13.25C21.5 13.1119 21.6119 13 21.75 13H23C23.4044 12.9999 23.7689 12.7563 23.9237 12.3827C24.0784 12.009 23.9929 11.579 23.707 11.293Z"
          fill={colour || MainColour}
        />
      </svg>
    </div>
  );
};

/* Phone icon */
const Phone = props => {
  const { width, height, style, colour } = getIconStyle(props);
  return (
    <div className="icon" style={style}>
      <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
        <path
          d="M22.673 17.009L20.221 14.556C19.6937 14.0277 18.9779 13.7309 18.2315 13.7309C17.4851 13.7309 16.7693 14.0277 16.242 14.556L15.742 15.056C13.2777 12.9913 11.0015 10.712 8.94 8.245L9.44 7.745C10.5362 6.64557 10.5362 4.86644 9.44 3.767L6.983 1.317C5.86871 0.252487 4.11429 0.252487 3 1.317L1.66 2.663C0.332824 3.99973 0.124179 6.08369 1.16 7.657C5.1551 13.6768 10.314 18.8367 16.333 22.833C17.9109 23.8588 19.9895 23.6509 21.333 22.333L22.678 20.987C23.2062 20.46 23.503 19.7446 23.503 18.9985C23.503 18.2524 23.2062 17.537 22.678 17.01L22.673 17.009Z"
          fill={colour || MainColour}
        />
      </svg>
    </div>
  );
};

/* Email icon */
const Email = props => {
  const { width, height, style, colour } = getIconStyle(props);
  return (
    <div className="icon" style={style}>
      <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
        <path
          d="M22.8672 1.116C22.7301 0.99675 22.5407 0.966698 22.3757 1.038L0.295672 10.538C0.115136 10.6164 -0.00165462 10.8011 0.000455367 11.0049C0.00256535 11.2086 0.123154 11.3906 0.305272 11.465L6.38399 13.924C6.53334 13.9846 6.70188 13.9636 6.83327 13.868L14.8973 7.894C15.1021 7.73976 15.3866 7.77736 15.548 7.97999C15.7093 8.18262 15.6922 8.48085 15.5088 8.662L8.78879 15.407C8.69493 15.5012 8.64191 15.6312 8.64191 15.767V22.5C8.64254 22.7253 8.78773 22.9223 8.9965 22.9812C9.20527 23.04 9.42604 22.9462 9.53567 22.752L12.576 17.327C12.642 17.21 12.7853 17.1688 12.8995 17.234L18.4925 20.434C18.6253 20.5102 18.7848 20.5166 18.9229 20.4512C19.0609 20.3859 19.1612 20.2564 19.1933 20.102L23.0333 1.602C23.0669 1.42059 23.0033 1.23449 22.8672 1.116Z"
          fill={colour || MainColour}
        />
      </svg>
    </div>
  );
};

/* User icon */
const User = props => {
  const { width, height, style, colour } = getIconStyle(props);
  return (
    <div className="icon" style={style}>
      <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C23.9928 5.37555 18.6244 0.00716485 12 0ZM18.134 17.9C17.9969 18.1179 17.7574 18.25 17.5 18.25H6.5C6.24278 18.2499 6.00357 18.118 5.86623 17.9005C5.72888 17.683 5.71257 17.4103 5.823 17.178C6.95318 14.7903 9.3583 13.2677 12 13.2677C14.6417 13.2677 17.0468 14.7903 18.177 17.178C18.2877 17.4101 18.2715 17.6827 18.134 17.9ZM15.7 8.05C15.7 6.00655 14.0435 4.35 12 4.35C9.95655 4.35 8.3 6.00655 8.3 8.05C8.3 10.0935 9.95655 11.75 12 11.75C14.0435 11.75 15.7 10.0935 15.7 8.05Z"
          fill={colour || '#fff'}
        />
      </svg>
    </div>
  );
};

/* Hamburger icon */
const Hamburger = props => {
  const { width, height, style, colour } = getIconStyle(props);
  return (
    <div className="icon" style={style}>
      <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
        <path
          d="M6 4.5C6 3.67157 6.67157 3 7.5 3H22.5C23.3284 3 24 3.67157 24 4.5V5.5C24 6.32843 23.3284 7 22.5 7H7.5C6.67157 7 6 6.32843 6 5.5V4.5Z"
          fill={colour || DarkGrey}
        />
        <path
          d="M3 11.5C3 10.6716 3.67157 10 4.5 10H19.5C20.3284 10 21 10.6716 21 11.5V12.5C21 13.3284 20.3284 14 19.5 14H4.5C3.67157 14 3 13.3284 3 12.5V11.5Z"
          fill={colour || DarkGrey}
        />
        <path
          d="M0 18.5C0 17.6716 0.671573 17 1.5 17H16.5C17.3284 17 18 17.6716 18 18.5V19.5C18 20.3284 17.3284 21 16.5 21H1.5C0.671573 21 0 20.3284 0 19.5V18.5Z"
          fill={colour || DarkGrey}
        />
      </svg>
    </div>
  );
};

/* View icon */
const View = props => {
  const { width, height, style, colour } = getIconStyle(props);
  return (
    <div className="icon" style={style}>
      <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.9782 16.2681L23.4374 20.7273C24.187 21.4771 24.187 22.6924 23.4374 23.4421C22.6824 24.1787 21.4776 24.1787 20.7226 23.4421L16.2634 18.9839C12.0535 21.7986 6.39518 20.9604 3.18173 17.0466C-0.0317175 13.1328 0.247827 7.41968 3.82802 3.83813C7.40821 0.256592 13.1213 -0.0249023 17.0363 3.18726C20.9513 6.39917 21.7913 12.0574 18.9782 16.2681ZM17.76 10.8047C17.76 6.96094 14.6439 3.84473 10.8 3.84473C6.95785 3.84888 3.84423 6.96265 3.84 10.8047C3.84 14.6487 6.9561 17.7646 10.8 17.7646C14.6439 17.7646 17.76 14.6487 17.76 10.8047Z"
          fill={colour || DarkGrey}
        />
      </svg>
    </div>
  );
};

/* Reveal icon */
const Reveal = props => {
  const { width, height, style, colour } = getIconStyle(props);
  return (
    <div className="icon" style={style}>
      <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.584 4.01196C16.594 4.19995 20.463 6.77002 23.092 9.66504C24.3022 10.9854 24.303 13.0115 23.094 14.333C21.887 15.6621 17.528 20 12.128 20H11.872C6.47101 20 2.11301 15.6621 0.90799 14.333C-0.301727 13.0117 -0.301727 10.9854 0.90799 9.66406C0.958008 9.60889 1.008 9.55396 1.05801 9.5C3.87601 6.46997 7.74701 4.03394 12 4C12.2 4 12.389 4 12.584 4.01196ZM12.972 17.9548C16.294 17.6418 19.444 15.375 21.614 12.9868C22.1226 12.4258 22.1239 11.5706 21.617 11.0078C20.8652 10.1809 20.0383 9.42529 19.147 8.75098C18.1038 7.948 16.9609 7.28345 15.747 6.77393C14.5642 6.26196 13.2888 5.99854 12 6C8.33203 5.91504 4.75201 8.3999 2.38501 11.011C1.8779 11.5728 1.8779 12.427 2.38501 12.989C2.49301 13.1082 2.60269 13.2258 2.71402 13.342C5.16 15.8899 8.37003 17.915 12 18C12.3246 18.0039 12.6492 17.9888 12.972 17.9548Z"
          fill={colour || DarkGrey}
        />
        <path
          d="M12 8C11.8691 8.00008 11.7382 8.00676 11.608 8.02C11.5212 8.02867 11.4453 8.08211 11.4079 8.16087C11.3704 8.23963 11.3769 8.33224 11.425 8.405C11.9689 9.22715 11.8304 10.3233 11.0991 10.9844C10.3678 11.6454 9.26323 11.6729 8.5 11.049C8.43316 10.9952 8.34341 10.9794 8.26223 11.0072C8.18106 11.035 8.11982 11.1025 8.1 11.186C8.03563 11.4526 8.00208 11.7257 8 12C8 14.2091 9.79086 16 12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8Z"
          fill={colour || DarkGrey}
        />
      </svg>
    </div>
  );
};

/* Hide icon */
const Hide = props => {
  const { width, height, style, colour } = getIconStyle(props);
  return (
    <div className="icon" style={style}>
      <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.9 6.94897C20.4882 7.95093 21.9344 9.16162 23.2 10.5488C23.9411 11.3718 23.9441 12.6206 23.207 13.447C20.645 16.2629 16.412 19.2468 12.156 19.2468H11.856C10.3715 19.2212 8.90671 18.9021 7.54599 18.3079L3.39999 22.4539C3.06273 22.79 2.53528 22.8418 2.13918 22.5774C1.74309 22.3132 1.58823 21.8064 1.769 21.366C1.77328 21.3616 1.77815 21.3591 1.78299 21.3564C1.78716 21.3542 1.79129 21.3521 1.795 21.3489L21.295 1.84888C21.3745 1.7666 21.4337 1.66699 21.468 1.55786L21.486 1.54004C21.7371 1.28003 22.1089 1.17578 22.4585 1.26733C22.8081 1.35864 23.0812 1.63184 23.1727 1.98145C23.2642 2.33105 23.1599 2.70288 22.9 2.95386L18.9 6.94897ZM13.313 16.2981C14.7503 15.8438 15.8721 14.7114 16.313 13.27C16.6246 12.2598 16.553 11.1699 16.112 10.209C16.0764 10.135 16.0069 10.083 15.9259 10.0698C15.8449 10.0564 15.7625 10.0835 15.705 10.1421L10.145 15.7009C10.0869 15.7588 10.0604 15.8413 10.0739 15.9221C10.0874 16.0029 10.1392 16.0723 10.213 16.1079C11.1866 16.5554 12.2921 16.623 13.313 16.2981Z"
          fill={colour || DarkGrey}
        />
        <path
          d="M7.61501 13.4001C7.67744 13.3371 7.70079 13.2452 7.67601 13.1601C7.5648 12.7832 7.50559 12.393 7.50001 12.0001C7.50001 9.51481 9.51472 7.50009 12 7.50009C12.3928 7.50421 12.7831 7.56242 13.16 7.67309C13.2453 7.69763 13.3372 7.67388 13.4 7.61109L15.341 5.66909C15.4034 5.60676 15.4291 5.51648 15.4087 5.43064C15.3883 5.3448 15.3248 5.27569 15.241 5.24809C14.1938 4.91197 13.0999 4.74389 12 4.75009C7.70001 4.69209 3.40001 7.70009 0.813006 10.5491C0.0609553 11.3684 0.0579195 12.6261 0.806006 13.4491C1.83921 14.5803 2.99193 15.5962 4.24401 16.4791C4.34547 16.5492 4.48251 16.537 4.57001 16.4501L7.61501 13.4001Z"
          fill={colour || DarkGrey}
        />
      </svg>
    </div>
  );
};

export default {
  Calculator,
  Plus,
  Minus,
  Edit,
  Check,
  Address,
  Phone,
  User,
  Hamburger,
  Email,
  View,
  Reveal,
  Hide,
};
