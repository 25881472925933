import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import parse from 'html-react-parser';

import { outerStyle, iconSize } from './style';
import Icons from '../Icons';

import './style.css';

export default function Address({ header, map }) {
  const data = useStaticQuery(graphql`
    query AddressQuery {
      wp {
        ...ContactDetails
        ...GoogleMap
      }
    }
  `);

  const contact = data.wp.options.websiteOptions.general;
  const googleMap = data.wp.options.awwSettings.map;

  const { address, phoneNumber, emailAddress } = contact;

  const county = contact.county && `, ${contact.county}`;
  const postCode = contact.postCode && ` ${contact.postCode}`;

  const fullAddress = address + county + postCode;

  return (
    <>
      <div className="address" style={outerStyle}>
        <ul>
          <li className="location noBullet">
            <Icons.Address size={iconSize} style={{ marginTop: `-30px` }} />
            <span>{fullAddress}</span>
          </li>
          <li className="phone noBullet">
            <Icons.Phone size={iconSize} />
            <span>
              <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
            </span>
          </li>
          {!header && (
            <li className="email noBullet">
              <Icons.Email size={iconSize} />
              <span>
                <a href={`mailto:${emailAddress}`}>{emailAddress}</a>
              </span>
            </li>
          )}
        </ul>
      </div>
      {map && googleMap && (
        <div className="embed-container" style={{ marginTop: `30px` }}>
          {parse(googleMap)}
        </div>
      )}
    </>
  );
}
